import * as getAllLicensePlateProvinceAction from "../../slices/master/licensePlateProvince.slice";
import { http } from "../../../utils/http";
import { apiUrl } from "../../../constants/server/api";

export const getAllLicensePlateProvince = () => {
  return async (dispatch) => {
    dispatch(getAllLicensePlateProvinceAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${apiUrl}/license-plate-province/list`,
      };

      const result = await http(config);
      dispatch(getAllLicensePlateProvinceAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      dispatch(getAllLicensePlateProvinceAction.fetchDataFailure());
    }
  };
};
