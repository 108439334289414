
import Text from 'fragments/Text';
import React, { useState,useEffect,useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import {CurrencyFormatText} from 'fragments/Currency';
import  DownloadExcel from 'utils/DownloadExcel';
export function Buy () {

    const [Insurance, setInsurance] = useState([])

    const getInsurance = () => {

        fetch('/json/quotation.json')
            .then((response) => response.json())
            .then((result) => setInsurance(result))
            .catch((error) => console.log('error', error))
    }


    useEffect(() => {
        getInsurance()
    }, []);



    const columnsd = [
        { 
            field: 'name', 
            headerClassName: 'bg-gray ps-4',
            headerName: 'บริษัทประกัน', width: 200,
            sortable: false,
            renderCell: ({ row }) => (
                <Text c="#8955C1" s='14px' w={400}  className='ps-3 '>{row.name}</Text>
            )
        
        },
        {
          field: 'insurance_fund',
          headerClassName: 'bg-gray',
          headerName: 'ทุนประกัน',
          width: 150,
          sortable: false,
          renderCell: ({ row }) => (
            <Text c='#4D4F5C' s='14px' w={400}><CurrencyFormatText value={row.insurance_fund} /></Text>
          )
        },
        {
          field: 'insurance_type',
          headerClassName: 'bg-gray',
          headerName: 'ประเภทประกัน',
          width: 150,
          sortable: false,
          renderCell: ({ row }) => (
            <Text c='#4D4F5C' s='14px' w={400}>{row.insurance_type}</Text>
          )
        },
        {
          field: 'price',
          headerClassName: 'bg-gray',
          headerName: 'ราคาเบี้ยประกัน',
          width: 150,
          sortable: false,
          renderCell: ({ row }) => (
            <Text c='#4D4F5C' s='14px' w={400}><CurrencyFormatText value={row.price} /></Text>
          )
        },
        {
          field: 'insurance_compulsory',
          headerClassName: 'bg-gray',
          headerName: 'ราคาเบี้ย พ.ร.บ',
 
          sortable: false,
          width: 150,
          renderCell: ({ row }) => (
            <Text c='#4D4F5C' s='14px' w={400}><CurrencyFormatText value={row.insurance_compulsory} /></Text>
          )
          
          
        },
        {
            field: 'marketing_cost',
            headerClassName: 'bg-gray',
            headerName: 'ค่าการตลาด',
            width: 150,
            sortable: false,
            renderCell: ({ row }) => (
                <Text c='#4D4F5C' s='14px' w={400}><CurrencyFormatText value={row.marketing_cost} /></Text>
            )
        },
        {
            field: 'id',
            headerClassName: 'bg-gray',
            headerName: 'จัดการ',
            width: 150,
            sortable: false,
            renderCell: ({ row }) => (
                <Button 
                style={{width:'80px',fontSize:'14px'}}
                className={`btn-st-1 py-2 fs-14 lh-sm  `}
                >ซื้อเลย</Button>
             )
        }
    ];



    const [filterText, setFilterText] = useState('');
    const filteredItems = Insurance.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    );



    return (
        <>
            <div className="ps-5" >
                <div className="fs-20 tc-7 className='ps-5' mb-4 lh-1">เลือกซื้อ </div>


                <div className="d-flex  flex-wrap">
                    <div className="card border-0 bg-white rouded-none me-5 mb-3">
                        <div className="card-body pe-5">
                            <div>
                                <Text c="#000A1B" s='16px' w={500} > เลขที่ใบเสนอราคา</Text>
                            </div>
                            <div className='my-2'>
                                <Text c="#8955C1" s='24px' w={600} > 933605</Text>
                            </div>

                            <table className='table table-borderless mb-0'>
                                <tbody>
                                    <tr>
                                        <td className='ps-0 py-1'> <Text c="#000A1B"  w={300} > วันที่ออก :</Text> </td>
                                        <td className='py-1'><Text c="#000A1B"  w={500} > 19.03.2024 15.00</Text></td>
                                    </tr>
                                    <tr>
                                        <td className='ps-0 py-1'> <Text c="#000A1B"  w={300} > วันที่ใช้ได้ถึง :</Text> </td>
                                        <td className='py-1'><Text c="#000A1B"  w={500} > 8.04.2024</Text> </td>
                                    </tr>
                                    <tr>
                                        <td className='ps-0 py-1'><Text c="#000A1B"  w={300} > ชื่อลูกค้า </Text> </td>
                                        <td className='py-1'><Text c="#000A1B"  w={500} > มุทิตา เมืองมา </Text></td>
                                    </tr>
                                    <tr>
                                        <td className='ps-0 py-1'><Text c="#000A1B"  w={300} > เบอร์โทรลูกค้า :</Text> </td>
                                        <td className=' py-1'><Text c="#000A1B"  w={500} > 0661541519 :</Text></td>
                                    </tr>
                                </tbody>
                            </table> 







                        </div>
                    </div>
                    <div className="card border-0 bg-white rouded-none me-5 mb-3">
                        <div className="card-body pe-5">
                            <div className='my-4 pb-3 pt-1'> </div>

                            <table className='table table-borderless mb-0'>
                                <tbody>
                                    <tr>
                                        <td className='ps-0 py-1'> <Text c="#000A1B"  w={300} > ยี่ห้อรถ/ปี :</Text> </td>
                                        <td className='py-1'><Text c="#000A1B"  w={500} >Honda/2020</Text></td>
                                    </tr>
                                    <tr>
                                        <td className='ps-0 py-1'> <Text c="#000A1B"  w={300} > รุ่นรถ/รุ่นย่อย :</Text> </td>
                                        <td className='py-1'><Text c="#000A1B"  w={500} > CITY/SV - 1500 CC</Text> </td>
                                    </tr>
                                    <tr>
                                        <td className='ps-0 py-1'><Text c="#000A1B"  w={300} > ทะเบียนรถ : </Text> </td>
                                        <td className='py-1'><Text c="#000A1B"  w={500} > ตย.555  </Text></td>
                                    </tr>
                                    <tr>
                                        <td className='ps-0 py-1'><Text c="#000A1B"  w={300} > จังหวัด :</Text> </td>
                                        <td className=' py-1'><Text c="#000A1B"  w={500} > กรุงเทพมหานคร</Text></td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div>
                    </div>
                </div>

                <div className="card border-0 bg-white   mb-5">
                    <div className="card-body px-0">
                        <div className='d-flex align-items-center mb-3 px-4'>
                            <DownloadExcel 
                            data={filteredItems}
                            filename="รายการ"
                            />
                            <div className='me-3 mb-2' style={{minWidth:'300px'}}>
                                <input type='text' className='form-control form-st-4' placeholder='ค้นหา' onKeyUp={e => setFilterText(e.target.value)} />
                            </div>
                        </div>
                        <DataGrid
                            getRowId={(row) => row.id}

                            rows={filteredItems}
                            autoHeight={true}
                            columns={columnsd}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            disableRowSelectionOnClick 
                            enableCellSelect={false}
                            pageSizeOptions={[5,10,15]}
                            slotProps={{ pagination: { labelRowsPerPage: 'จำนวนรายการต่อหน้า' } }}
                            disableMultipleRowSelection={true}
                            sx={{
                                boxShadow: 0,
                                border: 0,
                                fontWeight:900,
                                color:'#000000',
                                fontFamily: 'Sarabun',
                                fontSize:'14px',
                                borderRadius:0,
                                '& .MuiDataGrid-cell:focus': {
                                    outline: 'none',
                                },
                            
                                '& .MuiDataGrid-cell:focus-within': {
                                    outline: 'none',
                                },
                                '& .MuiTablePagination-selectLabel': {
                                    fontFamily: 'Sarabun',
                                    marginBottom:'3px'
                                },
                                '& .MuiTablePagination-displayedRows': {
                                    fontFamily: 'Sarabun',
                                    marginBottom:'3px'
                                },
                                '& .MuiDataGrid-filler': {
                                    background:'#F5F5F7'
                                }
                               
                                


                            }}
                        />
                    
                    </div>
                </div>


            </div>
        </>
    )
}