import * as carInsuranceAction from "../../slices/master/carInsurance.slice";
import { http } from "../../../utils/http";
import { apiUrl } from "../../../constants/server/api";
export const createCarInsurance = (data,navigate) => {
  return async (dispatch) => {
    dispatch(carInsuranceAction.createData());
    try {
      const config = {
        method: "post",
        url: `${apiUrl}/car-insurance`,
        data: data
      };

      const result = await http(config);
      if (result.status == 201 && result.data) {
        navigate(`/insurance/premium/list?car_insurance_id=${result.data.data.id}`);
      }
   
    } catch (error) {
      dispatch(carInsuranceAction.createDataFailure());
    }
  };
};

export const updateCarInsurance = (id,data,navigate) => {
  return async (dispatch) => {
    dispatch(carInsuranceAction.createData());
    try {
      const config = {
        method: "put",
        url: `${apiUrl}/car-insurance/${id}`,
        data: data
      };

      const result = await http(config);

       if (result.status == 200 && result.data) {
        navigate(`/insurance/premium/list?car_insurance_id=${result.data.data.id}`);
      } 
   
    } catch (error) {
      dispatch(carInsuranceAction.createDataFailure());
    }
  };
};


export const getCarInsuranceById = (id) => {
  return async (dispatch) => {
    dispatch(carInsuranceAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${apiUrl}/car-insurance/${id}`
      };

      const result = await http(config);
      dispatch(carInsuranceAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      dispatch(carInsuranceAction.fetchDataFailure());
    }
  };
};
