

export default Text = (
    {
        s='16px'
        ,c="rgba(121, 122, 122, 1)",
        children,
        w=400,
        className="",
        lh=1.2
    }
    ) => {
    return <>
        <span style={{
            fontSize:s,
            color:c,
            fontWeight:w,
            lineHeight:lh
            
        }} className={className}>{children}</span>
    </>
}
