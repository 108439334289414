

import React, { useState,useEffect } from 'react';
import {useNavigate,useLocation } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { DataGrid } from '@mui/x-data-grid';
import Text from 'fragments/Text';
import Image from 'react-bootstrap/Image';
export  function CustomerListSearch({formsearchcustomer,setModal,onSelect}){

    const [customerList, setCustomerList] = useState([])
    const [customerSelect, setCustomerSelect] = useState(false)

    const getCustomer = (formsearchcustomer) => {

        fetch('/json/customer.json')
            .then((response) => response.json())
            .then((result) => setCustomerList(result))
            .catch((error) => console.log('error', error))
    }


    useEffect(() => {
        getCustomer(formsearchcustomer)
    }, [formsearchcustomer]);

    const columnsd = [
        { 
            field: 'name', 
            headerClassName: 'bg-gray',
            headerName: 'ชื่อ', width: 200,
            sortable: false,
            renderCell: ({ row }) => (
                <div className='d-flex align-items-center'>
                    <Image  src={row.image} style={{width:'34px',height:'34px',borderRadius:'50%',objectFit:'cover'}} />
                    <div className='ms-2'>
                        { row.person_type == 2 ? row.business_name : row.name} 
                    </div>
                 </div>
            )
        
        },
        {
          field: 'tel',
          headerClassName: 'bg-gray',
          headerName: 'เบอร์โทรศัพท์',
          width: 150,
          sortable: false,
         },
        {
          field: 'car_license_plate',
          headerClassName: 'bg-gray',
          headerName: 'ทะเบียนรถ',
          width: 150,
          sortable: false,
        },
        {
          field: 'province',
          headerClassName: 'bg-gray',
          headerName: 'จังหวัด',
          width: 200,
          sortable: false,
        },
        {
          field: 'id',
          headerClassName: 'bg-gray',
          headerName: 'Action',
          align:'center',
          headerAlign:'center',
          sortable: false,
          width: 140,
          renderCell: ({ row }) => (
            <Button 
                onClick={() => setCustomerSelect(row) }
            className={`btn-st-4 py-2 fs-14 lh-sm ${customerSelect.id == row.id ? 'btn-st-4-active' : '' } `}
            >เลือก</Button>
         )
          
        }
    ];

    return (

        <>

            { customerList.length > 0 ?  (
            <>
                <div className='d-flex align-items-center mb-3'>
                    <div><Text c='rgba(76, 78, 100, 0.87)' s='20px' w={600}>ข้อมูลลูกค้า</Text></div>
                    
                </div>
                <div className='d-flex align-items-center mb-3'>
                    <div className='me-auto'> 
                        
                        <Text c='rgba(76, 78, 100, 0.87)' s='18px' >ผลลัพธ์</Text>
                        <Text c='#8955C1' className='px-2' s='18px' w={600}>{customerList.length}</Text>
                        <Text c='rgba(76, 78, 100, 0.87)' s='18px' >รายการ</Text>
                    </div>
                    <div>
                    <Button 
                        onClick={() => {
                            setCustomerSelect(false)
                            setModal( (prev) => ({...prev,open:false}) )
                        }}
                        className='btn-st-4 me-3'
                    >
                        ยกเลิก</Button>
                    <Button 
                        onClick={() => {
                            onSelect(customerSelect)
                            setModal( (prev) => ({...prev,open:false}) )
                        } }
                        disabled={!customerSelect}
                        className='btn-st-1'
                        style={{width:'130px',height:'42px'}}
                    >
                        ยืนยัน</Button>
                    </div>
                </div>
                <DataGrid
                    getRowId={(row) => row.id}

                    rows={customerList}
                    autoHeight={true}
                    columns={columnsd}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    enableCellSelect={false}
                    pageSizeOptions={[5,10,15]}
                    slotProps={{ pagination: { labelRowsPerPage: 'จำนวนรายการต่อหน้า' } }}
                    disableMultipleRowSelection={true}
                    sx={{
                        boxShadow: 0,
                        border: 0,
                        fontWeight:900,
                        color:'#000000',
                        fontFamily: 'Sarabun',
                        fontSize:'14px',
                        borderRadius:0,
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                    
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiTablePagination-selectLabel': {
                            fontFamily: 'Sarabun',
                            marginBottom:'3px'
                        },
                        '& .MuiTablePagination-displayedRows': {
                            fontFamily: 'Sarabun',
                            marginBottom:'3px'
                        }
                        


                    }}
                />
            </>
            )
            :  (
                <>
                    
                    <div className='d-flex justify-content-center align-items-center' style={{height:'400px'}}> 
                        <div className='text-center pt-5'>
                            <Image src="/images/empty.png" className='me-4' />

                            <div className='mt-4'>
                            <Text c='rgba(76, 78, 100, 0.87)' s="17px" w={600} > ไม่พบข้อมูล </Text>
                            </div>
                        </div>
                    </div>
                
                </>
            )}
        </>
        
    )


}