import * as getAllYearAction from "../../slices/master/year.slice";
import { http } from "../../../utils/http";
import { apiUrl } from "../../../constants/server/api";

export const getAllYear = () => {
  return async (dispatch) => {
    dispatch(getAllYearAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${apiUrl}/year`
      };

      const result = await http(config);
      dispatch(getAllYearAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      dispatch(getAllYearAction.fetchDataFailure());
    }
  };
};
