import { combineReducers } from "@reduxjs/toolkit";

// auth

// master
import getAllTestTableReducer from "./master/getAllTestTable.slice";
import carModelReducer from "./master/carModel.slice";
import carSubModelReducer from "./master/carSubModel.slice";
import insuranceTypeReducer from "./master/insuranceType.slice";
import yearReducer from "./master/year.slice";
import carBrandReducer from "./master/carBrand.slice";
import carCodeReducer from "./master/carCode.slice";
import licensePlateProvinceReducer from "./master/licensePlateProvince.slice";
import carInsuranceReducer from "./master/carInsurance.slice";
import placeReducer from "./master/place.slice";
import insuranceReducer from "./master/insurance.slice";
import insuranceAddonServiceReducer from "./master/insuranceAddonService.slice";
import insuranceCompanyReducer from "./master/insuranceCompany.slice";

// logic

const rootReducer = combineReducers({
  carBrandReducer: carBrandReducer,
  getAllTestTableReducer: getAllTestTableReducer,
  carModelReducer: carModelReducer,
  carCodeReducer: carCodeReducer,
  licensePlateProvinceReducer: licensePlateProvinceReducer,
  carSubModelReducer: carSubModelReducer,
  insuranceTypeReducer: insuranceTypeReducer,
  yearReducer: yearReducer,
  carInsuranceReducer: carInsuranceReducer,
  placeReducer: placeReducer,
  insuranceAddonServiceReducer: insuranceAddonServiceReducer,
  insuranceReducer: insuranceReducer,
  insuranceCompanyReducer: insuranceCompanyReducer
});
export default rootReducer;
