
import Form from 'react-bootstrap/Form';
import React, { useState,useEffect,useRef } from 'react';
import {useNavigate,useLocation } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import CurrencyFormat from 'react-currency-format';
import Text from 'fragments/Text';
import {CurrencyFormatText} from 'fragments/Currency';

import {useReactToPrint}  from 'react-to-print';


const IconCustomCheck = ({status}) => {
    return (
        <>
            <span>
                {
                    status ? 
                    <Image src='/images/icon/check.png' className='me-2' style={{marginTop:'-3px'}}></Image>  
                    :
                    <Image src='/images/icon/cross.png' className='me-2' style={{marginTop:'-3px'}}></Image>
                }
                
            </span>
        </>
    )
}

export function  Quotation() {
  
    const location = useLocation();


    const [quotation, setQuotation] = useState({});


    useEffect(() => {

        if(location.state){
            if(location.state){
                setQuotation((prev) => ({
                    ...prev,
                    compare:location.state.quotation.compare,
                    car:location.state.quotation.car,
                    compulsory:location.state.quotation.compulsory,
                    customer:location.state.customer
                }))
            }
            
        }
    }, [location]);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='pb-5 d-flex justify-content-center flex-column mx-auto' style={{maxWidth:'1240px'}} >
            

            <div className='d-flex align-items-center mb-2 pb-1'>
                <div className='me-auto'><Text c="#2F2F2F" s='18px'  > ใบเสนอราคา</Text></div>
                <div>
                    <Button 
                        className='btn btn-st-1 fs-16 px-2 bg-white border-0'
                        style={{width:'136px',color:'#8955C1'}}
                    >
                        <Image src="/images/icon/download.png" width={"20px"} style={{marginTop:'-2px',marginRight:'8px'}} />
                        ดาวน์โหลด
                        
                    </Button>
                    <Button 
                        className='btn btn-st-1 fs-16 px-2 bg-white border-0 mx-3'
                        style={{width:'106px',color:'#8955C1'}}
                    >
                        <Image src="/images/icon/share.png" width={"20px"} style={{marginTop:'-2px',marginRight:'8px'}} />
                        แชร์
                        
                    </Button>
                    <Button 
                        className='btn btn-st-1 fs-16 px-2 border-0'
                        style={{width:'136px'}}
                    >
                        ทำรายการต่อ
                        <Image src="/images/icon/ep_next_w.png" width={"20px"} style={{marginTop:'-2px',marginLeft:'8px'}} />
                    </Button>


                    


                </div>
            </div>
            <div>
                <div className='p-4 bg-white'>
                    <div className='d-flex justify-content-between mb-2'>
                        <div>
                            <div><Text c="#8955C1" s='20px' w={600} > ตรอ.หนองยายบู่เซอร์วิส</Text></div>
                            <div><Text c="#000A1B" s='14px' w={300} > ใบเสนอราคาจากบริษัทประกัน</Text></div>
                        </div>
                        <div className='text-end'>
                            <div><Text c="#000A1B" s='14px' w={500} > เลขที่ใบเสนอราคา</Text></div>
                            <div><Text c="#8955C1" s='24px' w={500} > 933605 </Text></div>
                            
                        
                        </div>
                    </div>

                    <div className='row justify-content-between'>
                        <div className='col-auto'>
                            <div className='mb-1'>
                                <Text c="#000A1B" s='16px' w={300} > ชื่อตัวแทน  :</Text>
                                <Text c="#000A1B" s='16px' w={600} className='ms-2' > ตรอ.หนองยายบู่เซอร์วิส</Text>
                            </div>
                            <div className='mb-1'>
                                <Text c="#000A1B" s='16px' w={300} > เบอร์โทรติดต่อตัวแทน  :</Text>
                                <Text c="#000A1B" s='16px' w={600} className='ms-2' > 0661541519</Text>
                            </div>
                            <div className='mb-1'>
                                <Text c="#000A1B" s='16px' w={300} > ที่อยู่  :</Text>
                                <Text c="#000A1B" s='16px' w={600} className='ms-2' > 60/138 ม.7, สุรศักดิ์, ศรีราชา, ชลบุรี - 20110</Text>
                            </div>
                        </div>

                        <div className='col-auto'>
                            <div className='mb-1'>
                                <Text c="#000A1B" s='16px' w={300} > ยี่ห้อรถ/ปี  :</Text>
                                <Text c="#000A1B" s='16px' w={600} className='ms-2' > Honda/2020</Text>
                            </div>
                            <div className='mb-1'>
                                <Text c="#000A1B" s='16px' w={300} > รุ่นรถ/รุ่นย่อย  :</Text>
                                <Text c="#000A1B" s='16px' w={600} className='ms-2' > CITY/SV - 1500 CC</Text>
                            </div>
                            <div className='mb-1'>
                                <Text c="#000A1B" s='16px' w={300} > ทะเบียนรถ  :</Text>
                                <Text c="#000A1B" s='16px' w={600} className='ms-2' > ตย.555 </Text>
                            </div>
                            <div className='mb-1'>
                                <Text c="#000A1B" s='16px' w={300} > จังหวัด  :</Text>
                                <Text c="#000A1B" s='16px' w={600} className='ms-2' > กรุงเทพมหานคร</Text>
                            </div>
                        </div>

                        <div className='col-auto'>
                            <div className='mb-1'>
                                <Text c="#000A1B" s='16px' w={300} > เลขบัตรนายหน้า:  :</Text>
                                <Text c="#000A1B" s='16px' w={600} className='ms-2' > None</Text>
                            </div>
                            <div className='mb-1'>
                                <Text c="#000A1B" s='16px' w={300} > รหัสตัวแทน  :</Text>
                                <Text c="#000A1B" s='16px' w={600} className='ms-2' >DJ-27927</Text>
                            </div>
                            <div className='mb-1'>
                                <Text c="#000A1B" s='16px' w={300} > วันที่ออก  :</Text>
                                <Text c="#000A1B" s='16px' w={600} className='ms-2' > 19.03.2024 15.00 </Text>
                            </div>
                            <div className='mb-1'>
                                <Text c="#000A1B" s='16px' w={300} > วันที่ใช้ได้ถึง  :</Text>
                                <Text c="#000A1B" s='16px' w={600} className='ms-2' > 18.04.2024</Text>
                            </div>
                        </div>
                    </div>
                </div>
                <table className='table table-st-1' >
                    <tbody>
                        <tr className='row-head'>
                            <td></td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-center py-3 position-relative' style={{width:'200px'}}>
                                    <Image src={`/images/company/${item.logo}`} style={{width:'50px',height:'50',objectFit:'cover'}} />
                                    <div className='text-center mt-1'>
                                        <Text c="#FFF" s='14px' > {item.name}</Text>
                                    </div>
                                    <div className='text-center mb-1'>
                                        <Text c="#FFF" s='14px' > ประกัน{item.insurance_type}</Text>
                                    </div>
    
                                </td>
                            )}
                        </tr>
                        <tr className='border-0' >
                            <td className='pt-4'>เบี้ยรวมภาคสมัครใจ</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end pt-4'>
                                    <Text c='#000'  w={600}><CurrencyFormatText value={item.price} /></Text>
                                </td>
                            )}
                        </tr>
                        { quotation.compulsory ? (
                            <tr className='border-0' >
                                <td className='pt-2'>เบี้ยรวม (พรบ.)</td>
                                { quotation.compare && quotation.compare.map((item,index) => 
                                    <td key={index} className='text-end '>
                                        <Text c='#000'  w={600}><CurrencyFormatText value={item.insurance_compulsory} /></Text>
                                    </td>
                                )}
                            </tr>
                        ) : ''}
                        
                        <tr>
                            <td valign='top'> <Text c="#8955C1"  w={600}> ส่วนลดจากตัวแทน (บาท)</Text> </td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'>

                                    <Text c='#8955C1'  w={600}><CurrencyFormatText value={item.agent_discount} /></Text>

                                </td>
                            )}
                        </tr>
                        <tr className='row-st-1 '>
                            <td style={{borderBottom:'1px solid #FFF'}}>   <Text c="#FFF" > เบี้ยรวมภาคสมัครใจ</Text> </td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end' style={{borderBottom:'1px solid #FFF'}}>
                                    <Text c='#FFF'  w={600}><CurrencyFormatText value={item.price} /></Text>
                                </td>
                            )}
                        </tr>
                        { quotation.compulsory ? (
                        <tr className='row-st-1 '>
                            <td>  <Text c="#FFF" > เบี้ยรวมภาคสมัครใจ + พรบ.</Text> </td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'>
                                    <Text c='#FFF'  w={600}><CurrencyFormatText value={item.price+item.insurance_compulsory} /></Text>
                                </td>
                            )}
                        </tr>
                        ) : '' }
                        <tr className='border-0'>
                            <td>วิธีการตรวจรถ</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'>{item.inspect_car}</td>
                            )}
                        </tr>

                        <tr className='row-st-2 r1' > 
                            <td style={{color:'#8955C1'}}>รายละเอียดแผนประกัน</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'></td>
                            )}
                        </tr>
                        <tr className='row-st-2 r2'>
                            <td>ทุนประกัน</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'><CurrencyFormatText value={item.insurance_fund} /> </td>
                            )}
                        </tr>
                        <tr className='row-st-2 r3'>
                            <td>ประเภทการซ่อม</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'>{item.repair_center}</td>
                            )}
                        </tr>
                        <tr className='row-st-2 r4'>
                            <td>ค่าเสียหายส่วนแรก</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'><CurrencyFormatText value={item.first_part_damages} /></td>
                            )}
                        </tr>

                        <tr className='border-0'>
                            <td > <Text c="#8955C1" >  รถยนต์เสียหาย สูญหาย ไฟไหม้</Text></td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'></td>
                            )}
                        </tr> 
                        <tr className='border-0'>
                            <td>ความเสียหายต่อรถยนต์</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'><CurrencyFormatText value={item.cars_damage} /></td>
                            )}
                        </tr>
                        <tr>
                            <td>รถยนต์สูญหาย/ไฟไหม้</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'><CurrencyFormatText value={item.car_lost_or_fires} /></td>
                            )}
                        </tr>


                        <tr className='border-0'>
                            <td> <Text c="#8955C1" >  คุ้มครองบุคคลภายนอก</Text> </td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'></td>
                            )}
                        </tr>

                        <tr className='border-0'>
                            <td>ชีวิตบุคคลภายนอก ต่อคน</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'><CurrencyFormatText value={item.outsider_life_per_person} /></td>
                            )}
                        </tr>
                        <tr className='border-0'>
                            <td>ชีวิตบุคคลภายนอก ต่อครั้ง</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'><CurrencyFormatText value={item.outsider_life_per_time} /></td>
                            )}
                        </tr>
                        <tr className='border-0'>
                            <td>ทรัพย์สินบุคคล ภายนอก</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'><CurrencyFormatText value={item.outsider_property} /></td>
                            )}
                        </tr>
                        <tr>
                            <td>ค่าเสียหายส่วนแรก กรณีทรัพย์สิน</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'><CurrencyFormatText value={item.first_part_damages_case_property_damage} /></td>
                            )}
                        </tr>


                        <tr className='border-0'>
                            <td> <Text c="#8955C1" >  คุ้มครองเพิ่มเติม</Text> </td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'></td>
                            )}
                        </tr>

                        <tr className='border-0'>
                            <td>อุบัติเหตุส่วนบุคคล</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'>{item.personal_accident}</td>
                            )}
                        </tr>
                        <tr className='border-0'>
                            <td>ค่ารักษาพยาบาล ต่อคน</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'>{item.medical_per_person}</td>
                            )}
                        </tr>
                        <tr >
                            <td>ประกันตัวผู้ขับขี่</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'><CurrencyFormatText value={item.driver_insurance} /></td>
                            )}
                        </tr>

                        <tr className='border-0'>
                            <td> <Text c="#8955C1" >  บริการเสริม</Text> </td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'></td>
                            )}
                        </tr>
                        
                        <tr className='border-0'>
                            <td>ช่วยเหลือฉุกเฉิน</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'><IconCustomCheck status={item.additional_services.emergency_help} /></td>
                            )}
                        </tr>
                        <tr className='border-0'>
                            <td>ภัยธรรมชาติ</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'><IconCustomCheck status={item.additional_services.natural_disaster} /></td>
                            )}
                        </tr>
                        <tr className='border-0'>
                            <td>ภัยน้ำท่วม</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'><IconCustomCheck status={item.additional_services.flood_danger                            } /></td>
                            )}
                        </tr>
                        <tr className='border-0'>
                            <td>รถใช้ระหว่างซ่อม</td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'><IconCustomCheck status={item.additional_services.car_used_during_repair} /></td>
                            )}
                        </tr>
                    
                        <tr className='border-0' style={{height:'100px'}}>
                            <td></td>
                            { quotation.compare && quotation.compare.map((item,index) => 
                                <td key={index} className='text-end'></td>
                            )}
                        </tr>


                    </tbody>
                </table>
            </div>

        </div>
    )
}