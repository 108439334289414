import axios from "axios";
import join from "url-join";
import { domain } from "../constants/server/api";
import {
  NETWORK_CONNECTION_MESSAGE,
  NOT_CONNECT_NETWORK_CODE,
} from "../constants/server/error";
import { createBrowserHistory } from 'history';

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

const history = createBrowserHistory();

axios.interceptors.request.use(async (config) => {
  if (!isAbsoluteURLRegex.test(config.url)) {
    config.url = join(domain, config.url);
  }
  config.timeout = 10 * 1000; // 10 seconds
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    } 
    else if (!error.response) {
      console.log(error);
      return Promise.reject({
        code: NOT_CONNECT_NETWORK_CODE,
        message: NETWORK_CONNECTION_MESSAGE,
      });
    } else if (error.response.status == 401) {
      history.push('/');
    }
    return Promise.reject(error);
  }
);

export const http = axios;
