import * as insuranceCompanyAction from "../../slices/master/insuranceCompany.slice";
import { http } from "../../../utils/http";
import { apiUrl } from "../../../constants/server/api";

export const getPaginationInsuranceCompany = (
  page = 1,
  perPage = 12,
  place,
  insurance,
  firstDamages,
  insuranceAddonService,
  min_current_insurance_value,
  max_current_insurance_value,
  marketingDisplay,
  sort
) => {
  return async (dispatch) => {
    dispatch(insuranceCompanyAction.fetchData());

    try {
      // สร้างวัตถุ params แบบไดนามิก
      const params = {
        page,
        perPage,
      };

      

      if (sort) params.sort = sort;
      if (place) params[`filter[place]`] = place;
      if (insurance) params[`filter[insurance]`] = insurance;
      if (firstDamages) params[`filter[firstDamages]`] = firstDamages;
      if (insuranceAddonService) params[`filter[insuranceAddonService]`] = insuranceAddonService;
      if (min_current_insurance_value) params[`filter[minCurrentInsuranceValue]`] = min_current_insurance_value;
      if (max_current_insurance_value) params[`filter[maxCurrentInsuranceValue]`] = max_current_insurance_value;
      if (marketingDisplay) params[`filter[marketingDisplay]`] = marketingDisplay;
    

      const config = {
        method: "get",
        url: `${apiUrl}/vendor-insurance`,
        params
      };

      const result = await http(config);
      dispatch(insuranceCompanyAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      dispatch(insuranceCompanyAction.fetchDataFailure());
    }
  };
};
