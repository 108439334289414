
import Form from 'react-bootstrap/Form';
import React, { useState,useEffect } from 'react';
import {useNavigate,useLocation } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import CurrencyFormat from 'react-currency-format';
import Text from 'fragments/Text';
import {CurrencyFormatText} from 'fragments/Currency';
const IconCustomCheck = ({status}) => {
    return (
        <>
            <span>
                {
                    status ? 
                    <Image src='/images/icon/check.png' className='me-2' style={{marginTop:'-3px'}}></Image>  
                    :
                    <Image src='/images/icon/cross.png' className='me-2' style={{marginTop:'-3px'}}></Image>
                }
                
            </span>
        </>
    )
}

export function  CompareDetail() {
    const navigate = useNavigate();
    const location = useLocation();


    const [compare, setCompare] = useState([]);
    const [formdata, setFormdata] = useState(null);


    useEffect(() => {
        
        if(location.state.compare){
            setCompare(location.state.compare)
        }
        if(location.state.formdata){
            setFormdata(location.state.formdata)
        }
    }, [location]);


    const [marketingDisplay, setMarketingDisplay] = useState(false);
    const [compulsory, setCompulsory] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className='pb-5 d-flex justify-content-center flex-column mx-auto' style={{maxWidth:'1240px'}} >
            <div className='d-flex align-items-center justify-content-between mb-3'>
                <Button className='btn btn-link text-decoration-none fs-16'
                 onClick={() => {
                    navigate('/insurance/premium/list',{state:{compare:compare,formdata:formdata}});
                 }}
                >
                    <Image src={`/images/icon/ep_back.png`} style={{width:'24px'}} />
                    <Text c="#000" s='14px' > กลับก่อนหน้า</Text>
                </Button>
                <div
                style={{
                    border: '1px solid #8955C1',
                    padding:'6px 10px',
                    paddingBottom:'3px',
                    borderRadius: '8px',
                    background:'#FFF',
                    
                }}
                >
                    <Form.Check
                        style={{color:'#8955C1',lineHeight:2}}
                        type="checkbox"
                        label='ซื้อพร้อม พรบ.'
                        id={`check-atc`}
                        checked={compulsory}
                        onChange={(e) => {
                            if(e.target.checked){
                                setCompulsory(true)
                            }else{
                                setCompulsory(false)
                            }
                        }}
                        
                    />
                </div>
                <Button className='btn btn-st-1 fs-16'
                    onClick={() => {
                        navigate('/insurance/premium/customer',{state:{compare:compare,car:formdata,compulsory:compulsory}});
                    }}
                >ออกใบเสนอราคา</Button>
            </div>

            <div className='mb-3 d-flex justify-content-end ' style={{color:'rgba(76, 78, 100, 0.87)',lineHeight:1.6}}>
                <Form.Check 
                    type="switch"
                    id="custom-switch"
                    label="ซ่อนค่าการตลาด"
                    className='custom-switch'
                    onChange={(e) => {
                        setMarketingDisplay(e.target.checked)
                    }}
                    checked={marketingDisplay}
                />
            </div>

            <table className='table table-st-1' >
                <tbody>
                    <tr className='row-head'>
                        <td></td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-center py-3 position-relative' style={{width:'200px'}}>
                                <Image src={`/images/company/${item.logo}`} style={{width:'50px',height:'50',objectFit:'cover'}} />
                                <div className='text-center mt-1'>
                                    <Text c="#FFF" s='14px' > {item.name}</Text>
                                </div>
                                <div className='text-center mb-1'>
                                    <Text c="#FFF" s='14px' > ประกัน{item.insurance_type}</Text>
                                </div>
                                <div className='text-center'>
                                    <button 
                                        className='btn fs-14 py-1 text-white'
                                        style={{
                                            background: '#FFA319',
                                            width:'90px'
                                            
                                        }}
                                    >ซื้อเลย</button>
                                </div>
                                <button 
                                    className='btn fs-14 position-absolute p-0'
                                    style={{
                                        right:'35px',
                                        top:'15px'
                                        
                                    }}
                                    onClick={() => {
                                        setCompare(prev => prev.filter(item2 => item2.id !== item.id))
                                    }}
                                >
                                    <Image src={`/images/icon/healthicons_no.png`} style={{width:'24px',height:'24px',objectFit:'cover'}} />
                                        
                                </button>
                            </td>
                        )}
                    </tr>
                    <tr className='border-0' >
                        <td className='pt-4'>เบี้ยรวมภาคสมัครใจ</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end pt-4'>
                                <Text c='#000'  w={600}><CurrencyFormatText value={item.price} /></Text>
                            </td>
                        )}
                    </tr>
                    { compulsory ? (
                        <tr className='border-0' >
                            <td className='pt-2'>เบี้ยรวม (พรบ.)</td>
                            { compare && compare.map((item,index) => 
                                <td key={index} className='text-end '>
                                    <Text c='#000'  w={600}><CurrencyFormatText value={item.insurance_compulsory} /></Text>
                                </td>
                            )}
                        </tr>
                    ) : ''}
                    
                    <tr>
                        <td valign='top'> <Text c="#8955C1"  w={600}> ส่วนลดจากตัวแทน (บาท)</Text> </td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'>
                                <div >
                                    <CurrencyFormat 
                                        className='form-st-2 form-control text-end ms-auto my-0' 
                                        value={item.agent_discount ? item.agent_discount : ''}
                                        displayType={'input'} 
                                        thousandSeparator={true} 
                                        prefix={''} 
                                        decimalScale={2} 
                                        fixedDecimalScale={true}
                                        placeholder='0.00'
                                        onValueChange={(values) => {
                                            const {formattedValue, value} = values;
                                            var val = value
                                            if(value > item.max_agent_discount){
                                                val = item.max_agent_discount;
                                            }
                                            setCompare(prev => prev.map(s => 
                                                s.id === item.id ? {...s, agent_discount: val} : s
                                            ));

                                        }}
                                        style={{width:'80%'}}
                                    
                                    />
                                </div>
                                <span className='text-danger fs-12 pt-1' ><Text c="#FF0000" s='12px' >ไม่เกิน <CurrencyFormatText value={item.max_agent_discount} />  บาท</Text> </span>
                            </td>
                        )}
                    </tr>
                    <tr className='row-st-1 '>
                        <td style={{borderBottom:'1px solid #FFF'}}>   <Text c="#FFF" > เบี้ยรวมภาคสมัครใจ</Text> </td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end' style={{borderBottom:'1px solid #FFF'}}>
                                <Text c='#FFF'  w={600}><CurrencyFormatText value={item.price} /></Text>
                            </td>
                        )}
                    </tr>
                    { compulsory ? (
                    <tr className='row-st-1 '>
                        <td>  <Text c="#FFF" > เบี้ยรวมภาคสมัครใจ + พรบ.</Text> </td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'>
                                <Text c='#FFF'  w={600}><CurrencyFormatText value={item.price+item.insurance_compulsory} /></Text>
                            </td>
                        )}
                    </tr>
                    ) : '' }
                    <tr className='border-0'>
                        <td>วิธีการตรวจรถ</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'>{item.inspect_car}</td>
                        )}
                    </tr>

                    <tr className='row-st-2 r1' > 
                        <td style={{color:'#8955C1'}}>รายละเอียดแผนประกัน</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'></td>
                        )}
                    </tr>
                    <tr className='row-st-2 r2'>
                        <td>ทุนประกัน</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'><CurrencyFormatText value={item.insurance_fund} /> </td>
                        )}
                    </tr>
                    <tr className='row-st-2 r3'>
                        <td>ประเภทการซ่อม</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'>{item.repair_center}</td>
                        )}
                    </tr>
                    <tr className='row-st-2 r4'>
                        <td>ค่าเสียหายส่วนแรก</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'><CurrencyFormatText value={item.first_part_damages} /></td>
                        )}
                    </tr>

                    <tr className='border-0'>
                        <td > <Text c="#8955C1" >  รถยนต์เสียหาย สูญหาย ไฟไหม้</Text></td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'></td>
                        )}
                    </tr> 
                    <tr className='border-0'>
                        <td>ความเสียหายต่อรถยนต์</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'><CurrencyFormatText value={item.cars_damage} /></td>
                        )}
                    </tr>
                    <tr>
                        <td>รถยนต์สูญหาย/ไฟไหม้</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'><CurrencyFormatText value={item.car_lost_or_fires} /></td>
                        )}
                    </tr>


                    <tr className='border-0'>
                        <td> <Text c="#8955C1" >  คุ้มครองบุคคลภายนอก</Text> </td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'></td>
                        )}
                    </tr>

                    <tr className='border-0'>
                        <td>ชีวิตบุคคลภายนอก ต่อคน</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'><CurrencyFormatText value={item.outsider_life_per_person} /></td>
                        )}
                    </tr>
                    <tr className='border-0'>
                        <td>ชีวิตบุคคลภายนอก ต่อครั้ง</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'><CurrencyFormatText value={item.outsider_life_per_time} /></td>
                        )}
                    </tr>
                    <tr className='border-0'>
                        <td>ทรัพย์สินบุคคล ภายนอก</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'><CurrencyFormatText value={item.outsider_property} /></td>
                        )}
                    </tr>
                    <tr>
                        <td>ค่าเสียหายส่วนแรก กรณีทรัพย์สิน</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'><CurrencyFormatText value={item.first_part_damages_case_property_damage} /></td>
                        )}
                    </tr>


                    <tr className='border-0'>
                        <td> <Text c="#8955C1" >  คุ้มครองเพิ่มเติม</Text> </td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'></td>
                        )}
                    </tr>

                    <tr className='border-0'>
                        <td>อุบัติเหตุส่วนบุคคล</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'>{item.personal_accident}</td>
                        )}
                    </tr>
                    <tr className='border-0'>
                        <td>ค่ารักษาพยาบาล ต่อคน</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'>{item.medical_per_person}</td>
                        )}
                    </tr>
                    <tr >
                        <td>ประกันตัวผู้ขับขี่</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'><CurrencyFormatText value={item.driver_insurance} /></td>
                        )}
                    </tr>

                    <tr className='border-0'>
                        <td> <Text c="#8955C1" >  บริการเสริม</Text> </td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'></td>
                        )}
                    </tr>
                    
                    <tr className='border-0'>
                        <td>ช่วยเหลือฉุกเฉิน</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'><IconCustomCheck status={item.additional_services.emergency_help} /></td>
                        )}
                    </tr>
                    <tr className='border-0'>
                        <td>ภัยธรรมชาติ</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'><IconCustomCheck status={item.additional_services.natural_disaster} /></td>
                        )}
                    </tr>
                    <tr className='border-0'>
                        <td>ภัยน้ำท่วม</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'><IconCustomCheck status={item.additional_services.flood_danger                            } /></td>
                        )}
                    </tr>
                    <tr className='border-0'>
                        <td>รถใช้ระหว่างซ่อม</td>
                        { compare && compare.map((item,index) => 
                            <td key={index} className='text-end'><IconCustomCheck status={item.additional_services.car_used_during_repair} /></td>
                        )}
                    </tr>
                   



                </tbody>
            </table>
        </div>
    )
}