import CurrencyFormat from 'react-currency-format';


export const CurrencyFormatText = ({value}) => {
    
    if(isNaN(value)){
        return value;
    }

    return (
        <CurrencyFormat 
            value={value}
            displayType={'text'} 
            thousandSeparator={true} 
            prefix={''} 
            decimalScale={2} 
            fixedDecimalScale={true}
        />
    )
}

export default null