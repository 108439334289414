import * as getAllCarSubModelAction from "../../slices/master/carSubModel.slice";
import { http } from "../../../utils/http";
import { apiUrl } from "../../../constants/server/api";

export const getAllCarSubModel = (car_model_id) => {
  return async (dispatch) => {
    dispatch(getAllCarSubModelAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${apiUrl}/car-sub-model/list`,
        params: {
          car_model_id
        },
      };

      const result = await http(config);
      dispatch(getAllCarSubModelAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      dispatch(getAllCarSubModelAction.fetchDataFailure());
    }
  };
};
