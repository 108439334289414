
import {NavLink} from "react-router-dom";
export function  Header({selectbranchID}) {


    const handleEnterHoverSidebar = () => {
        if(document.body.classList.contains('sidenav-toggled')) {
            document.body.classList.add("sidenav-toggled-open")
        }
    }

    const handleLeaveHoverSidebar = () => {
        if(document.body.classList.contains('sidenav-toggled')) {
            document.body.classList.remove("sidenav-toggled-open")
        } 
    }

    return (    
        <div>
            <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
            <aside className="app-sidebar sidebar-scroll"
                onMouseEnter={() => handleEnterHoverSidebar() }
                onMouseLeave={() => handleLeaveHoverSidebar() }
            >
                <div className="main-sidebar-header px-0 active">
                    <a className="desktop-logo active" href="">
                        <img src="/images/logo.png" className="main-logo" alt="logo" />
                    </a>
                    <a className="logo-icon mobile-logo py-3 px-0 icon-light active" href="">
                        <img src="/images/logo-3.png" className="logo-icon mx-auto" alt="logo" />
                    </a>
                </div>
                <div className="main-sidemenu px-2 pt-4">
                    <ul className="side-menu">
                        <li className="slide"  >
                            <a className="side-menu__item py-1  "  href="#">
                                <div className="side-menu_icon"><div className="side-menu__icon icon-home"></div></div>
                                <span className="side-menu__label"> INDEX</span>
                            </a>
                        </li>
                        <li className="slide"  >
                            <NavLink
                                to={`/insurance/premium`}
                                className="side-menu__item py-1 "
                            >
                                <div className="side-menu_icon"><div className="side-menu__icon icon-business"></div></div>
                                <span className="side-menu__label"> ระบบเช็คเบี้ย</span>
                            </NavLink>
                        </li>
                        <li className="slide"  >
                            <a className="side-menu__item py-1 "  href="#">
                                <div className="side-menu_icon"><div className="side-menu__icon icon-document-add"></div></div>
                                <span className="side-menu__label"> จัดการใบเสนอราคา</span>
                            </a>
                        </li>
                        <li className="slide"  >
                            <a className="side-menu__item py-1 "  href="#">
                                <div className="side-menu_icon"><div className="side-menu__icon icon-profile"></div></div>
                                <span className="side-menu__label"> ข้อมูลลูกค้า</span>
                            </a>
                        </li>
                        <li className="slide"  >
                            <a className="side-menu__item py-1 "  href="#">
                                <div className="side-menu_icon"><div className="side-menu__icon icon-userid"></div></div>
                                <span className="side-menu__label"> จัดการ Subagent</span>
                            </a>
                        </li>
                        <li className="slide"  >
                            <a className="side-menu__item py-1 "  href="#">
                                <div className="side-menu_icon"><div className="side-menu__icon icon-wallet"></div></div>
                                <span className="side-menu__label"> การเงิน</span>
                            </a>
                        </li>
                        <li className="slide"  >
                            <a className="side-menu__item py-1 "  href="#">
                                <div className="side-menu_icon"><div className="side-menu__icon icon-presention"></div></div>
                                <span className="side-menu__label"> ค่าการตลาด</span>
                            </a>
                        </li>
                        <li className="slide"  >
                            <a className="side-menu__item py-1 "  href="#">
                                <div className="side-menu_icon"><div className="side-menu__icon icon-document"></div></div>
                                <span className="side-menu__label"> ต่ออายุ</span>
                            </a>
                        </li>

                        


                    </ul>
                </div>
            </aside>
        </div>
    );
}