const domain = "https://deejing-dev-api.seamlesssolution.co";

export { domain };

export const apiUrl = "/api/v1";

export const YES = "YES";
export const NO = "NO";
export const OK = "OK";
export const TOKEN = "TOKEN";
export const VER = "1";

export const endPoint = {
  TEST_TABLE_URL: `${apiUrl}/test-table`,
};
