import * as placeAction from "../../slices/master/place.slice";
import { http } from "../../../utils/http";
import { apiUrl } from "../../../constants/server/api";

export const getAllPlace = () => {
  return async (dispatch) => {
    dispatch(placeAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${apiUrl}/place`
      };

      const result = await http(config);
      dispatch(placeAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      dispatch(placeAction.fetchDataFailure());
    }
  };
};
