import * as insuranceAction from "../../slices/master/insurance.slice";
import { http } from "../../../utils/http";
import { apiUrl } from "../../../constants/server/api";

export const getPaginationInsurance = (page=1,perPage=12,s="") => {
  return async (dispatch) => {
    dispatch(insuranceAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${apiUrl}/insurance/pagination/client`,
        params: {
          page,
          perPage,
          s
        }
      };

      const result = await http(config);
      dispatch(insuranceAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      dispatch(insuranceAction.fetchDataFailure());
    }
  };
};
