import Form from 'react-bootstrap/Form';
import React, { useState,useEffect } from 'react';
import Text from 'fragments/Text';

import Col from 'react-bootstrap/Col';
import CurrencyFormat from 'react-currency-format';
import { getAllPlace } from "../redux/handlers/master/place.handler";
import { getPaginationInsurance } from "../redux/handlers/master/insurance.handler";
import { getAllInsuranceAddonService } from "../redux/handlers/master/insuranceAddonService.handler";
import { useAppDispatch ,useAppSelector} from "../redux/store/configureStore";

export const RepairType = ({objFilterType}) => {
    const dispatch = useAppDispatch();
    const [repairtype, setRepairtype] = useState(false);

    useEffect(() => {
        dispatch(getAllPlace());
    }, []);

    const placeReducer = useAppSelector(
        (state) => state.placeReducer
      );

      useEffect(() => {
        if (placeReducer.data) {
            setRepairtype(placeReducer.data.data);
        }
      }, [placeReducer]);

    return (
        <>
            <div className="mb-2">
                <Text c="#8955C1" s="18px"> ประเภทการซ่อม</Text>
                <Text c="#1D1D1D" s="12px" className='ps-1'> (เลือกได้มากกว่า 1 ข้อ)</Text>
            </div>
            <div className="p-1">
                <div className='d-flex flex-wrap ' >
                    {
                        repairtype && repairtype.map((item,index) => {
                            
                            const checkfilter = objFilterType.FilterType.filter(item2 => item2.id == item.id);
                            return (
                                <div
                                style={{
                                    border: '1px solid #C9C9C9',
                                    padding:'6px 10px',
                                    paddingBottom:'4px',
                                    borderRadius: '8px',
                                    minWidth:'calc(50% - 7px)',
                                    marginRight:'7px',
                                    marginBottom:'5px',
                                    background:'#FFF',
                                    
                                }}
                                key={index}
                                >
                                    <Form.Check
                                        type="checkbox"
                                        label={item.name}
                                        id={`check-${index}`}
                                        checked={checkfilter.length > 0}
                                        
                                        onChange={(e) => {
                                            if(e.target.checked){
                                                objFilterType.setFilterType((prev) => [...prev,item])
                                            }else{
                                                objFilterType.setFilterType(prev => prev.filter(item2 => item2.id !== item.id))
                                            }
                                            
                                        }}
                                        
                                    />
                                </div>
                            )
                        }
                        
                        )
                    }
                </div>
            </div>
        </>
    )

}

export const FirstPartDamages = ({objFilter}) => {

    const [damages, setDamagestype] = useState(false);
    const [checkall, setCheckall] = useState(false);



    
    const getType = () => {
        setDamagestype([
            {
                id:1,
                value:"ไม่มี",
                name:"ไม่มีค่าเสียหายส่วนแรก"
            },
            {
                id:2,
                value:3000,
                name:"ค่าเสียหายส่วนแรก 3,000 บาท"
            },
            {
                id:3,
                value:5000,
                name:"ค่าเสียหายส่วนแรก 5,000 บาท"
            }
        ])
    };

    useEffect(() => {
        getType();
    }, []);



    return (
        <>
            <div className="p-1">
                <div className="mb-2 pb-1">
                    <Text c="#8955C1" s="18px"> ค่าเสียหายส่วนแรก </Text>
                    <Text c="#1D1D1D" s="12px" className='ps-1'> (เลือกได้มากกว่า 1 ข้อ)</Text>
                </div>
                <div className='' >

                    <div
                        style={{
                            padding:'0px 5px',
                            paddingBottom:'5px',
                            marginBottom:'0px',
                            lineHeight:1.8
                        }}
                    >
                        <Form.Check
                            type="checkbox"
                            label="ทั้งหมด"
                            id={`fall`}
                            checked={checkall && objFilter.FilterFirstDamages.length  == damages.length}
                            onChange={(e) => {
                                setCheckall(e.target.checked)
                                if(e.target.checked){
                                    objFilter.setFilterFirstDamages(damages)
                                }else{
                                    objFilter.setFilterFirstDamages([])
                                } 
                            }}
                        />
                    </div>

                    {
                        damages && damages.map((item,index) => {
                            return (
                                <div
                                style={{
                                    padding:'0px 5px',
                                    paddingBottom:'5px',
                                    marginBottom:'0px',
                                    lineHeight:1.8
                                }}
                                key={index}
                                >
                                    <Form.Check
                                        type="checkbox"
                                        label={item.name}
                                        id={`damages-${index}`}
                                        checked={objFilter.FilterFirstDamages.filter((item2) => item2.id == item.id  ).length > 0 }
                                        onChange={(e) => {
                                            
                                            if(e.target.checked){
                                                objFilter.setFilterFirstDamages((prev) => [...prev,item])
                                            }else{
                                                objFilter.setFilterFirstDamages(prev => prev.filter(item2 => item2.id !== item.id))
                                            }
                                        }}
                                    
                                    />
                                </div>
                            )
                        }
                        
                        )
                    }
                </div>
            </div>
        </>
    )
}

export const InsuranceFund = ({objFilter}) => {

    return (
        <>
            <div className="p-1">
                <div className="mb-2 pb-1">
                    <Text c="#8955C1" s="18px"> ทุนประกัน </Text>
                </div>
                <div className=''>
                <div className='row gx-0 align-items-center' >
                    <Col>
                        <CurrencyFormat 
                            value={objFilter.FilterFund.min}
                            displayType={'input'} 
                            thousandSeparator={true} 
                            prefix={'฿'} 
                            decimalScale={2} 
                            fixedDecimalScale={true}
                            style={{color:"#1D1D1D"}}
                            placeholder='฿ 0.00'
                            className='form-st-1 form-control'
                            onValueChange={(values) => {
                                const {formattedValue, value} = values;

                                objFilter.setFilterFund((prev) => ({
                                    ...prev,
                                    min: value
                                }))
                                
                            }}
                        /> 
                    </Col>
                    <Col xs="auto">
                        <Text c="#797A7A" s="18px" className='px-3'> - </Text>
                    </Col>
                    <Col>
                        <CurrencyFormat 
                            value={objFilter.FilterFund.max}
                            displayType={'input'} 
                            thousandSeparator={true} 
                            prefix={'฿'} 
                            decimalScale={2} 
                            fixedDecimalScale={true}
                            style={{color:"#1D1D1D"}}
                            placeholder='฿ 0.00'
                            className='form-st-1 form-control'
                            onValueChange={(values) => {
                                const {formattedValue, value} = values;

                                objFilter.setFilterFund((prev) => ({
                                    ...prev,
                                    max: value
                                }))
                            }}
                        /> 
                    </Col>
                
                </div>
                </div>
            </div>
        </>
    )

}
export const AdditionalServices = ({objFilterService}) => {

    const [services, setServices] = useState(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getAllInsuranceAddonService());
    }, []);

    const insuranceAddonServiceReducer = useAppSelector(
        (state) => state.insuranceAddonServiceReducer
      );

      useEffect(() => {
        if (insuranceAddonServiceReducer.data) {
            setServices(insuranceAddonServiceReducer.data.data);
        }
      }, [insuranceAddonServiceReducer]);

    const [checkall, setCheckall] = useState(false);

    return (
        <>
            <div className="p-1">
                <div className="mb-2 pb-1">
                    <Text c="#8955C1" s="18px"> บริการเสริม </Text>
                    <Text c="#1D1D1D" s="12px" className='ps-1'> (เลือกได้มากกว่า 1 ข้อ)</Text>
                </div>
                <div className='' >
                    <div
                        style={{
                            padding:'0px 5px',
                            paddingBottom:'5px',
                            marginBottom:'0px',
                            lineHeight:1.8
                        }}
                    >
                        <Form.Check
                            type="checkbox"
                            label="ทั้งหมด"
                            id={`aall`}
                            checked={checkall && objFilterService.FilterService.length  == services.length}
                            onChange={(e) => {
                                setCheckall(e.target.checked)
                                if(e.target.checked){
                                    objFilterService.setFilterService(services)
                                }else{
                                    objFilterService.setFilterService([])
                                } 
                            }}
                        />
                    </div>
                    {
                        services && services.map((item,index) => {
                            return (
                                <div
                                style={{
                                    padding:'0px 5px',
                                    paddingBottom:'5px',
                                    marginBottom:'0px',
                                    lineHeight:1.8
                                }}
                                key={index}
                                >
                                    <Form.Check
                                        type="checkbox"
                                        label={item.name}
                                        id={`AdditionalServices-${index}`}
                                        checked={objFilterService.FilterService.filter((item2) => item2.id == item.id  ).length > 0 }
                                        onChange={(e) => {
                                            
                                            if(e.target.checked){
                                                objFilterService.setFilterService((prev) => [...prev,item])
                                            }else{
                                                objFilterService.setFilterService(prev => prev.filter(item2 => item2.id !== item.id))
                                            }
                                        }}
                                        
                                    />
                                </div>
                            )
                        }
                        
                        )
                    }
                </div>
            </div>
        </>
    )

}

export const InsuranceCompany = ({ objFilterCompany }) => {

    const [company, setCompany] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [checkall, setCheckall] = useState(false);

    const dispatch = useAppDispatch();

    const insuranceReducer = useAppSelector((state) => state.insuranceReducer);

    useEffect(() => {
        // Initial load with no filter
        dispatch(getPaginationInsurance(1, 10, ""));
    }, [dispatch]);

    useEffect(() => {
        if (insuranceReducer.data) {
            setCompany(insuranceReducer.data.data.data);
        }
    }, [insuranceReducer]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            // Call API with filterText after 2 seconds
            dispatch(getPaginationInsurance(1, 10, filterText));
        }, 1000);

        // Clean up the timeout if the filterText changes within the 2-second window
        return () => clearTimeout(delayDebounceFn);
    }, [filterText, dispatch]);

    return (
        <>
            <div className="p-1">
                <div className="mb-2 pb-1">
                    <Text c="#8955C1" s="18px">บริษัทประกันภัย</Text>
                    <Text c="#1D1D1D" s="12px" className='ps-1'>(เลือกได้มากกว่า 1 ข้อ)</Text>
                </div>
                <div className='mb-3'>
                    <input
                        type='text'
                        className="form-control input-search"
                        placeholder='ค้นหาบริษัทประกัน'
                        onKeyUp={(e) => setFilterText(e.target.value)}
                    />
                </div>
                <div className=''>
                    <div
                        style={{
                            padding: '0px 5px',
                            paddingBottom: '5px',
                            marginBottom: '0px',
                            lineHeight: 1.8
                        }}
                    >
                        <Form.Check
                            type="checkbox"
                            label="ทั้งหมด"
                            id={`Companyall`}
                            checked={checkall && objFilterCompany.FilterCompany.length === company.length}
                            onChange={(e) => {
                                setCheckall(e.target.checked);
                                if (e.target.checked) {
                                    objFilterCompany.setFilterCompany(company);
                                } else {
                                    objFilterCompany.setFilterCompany([]);
                                }
                            }}
                        />
                    </div>
                    {company && company.map((item, index) => (
                        <div
                            style={{
                                padding: '0px 5px',
                                paddingBottom: '5px',
                                marginBottom: '0px',
                                lineHeight: 1.8
                            }}
                            key={index}
                        >
                            <Form.Check
                                type="checkbox"
                                label={item.name}
                                id={`Company-${index}`}
                                checked={objFilterCompany.FilterCompany.some((item2) => item2.id === item.id)}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        objFilterCompany.setFilterCompany((prev) => [...prev, item]);
                                    } else {
                                        objFilterCompany.setFilterCompany(prev => prev.filter(item2 => item2.id !== item.id));
                                    }
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}