import React, { useState } from 'react';


import { Layout } from "./layouts/layout";
import { InsurancePremiumFilter } from "pages/insurance/formSearch";
import { InsuranceList } from "pages/insurance/insuranceList";
import { CompareDetail } from "pages/insurance/compare";
import { CustomerSelect } from "pages/insurance/customer";
import { Quotation } from "pages/insurance/quotation";
import { Buy } from "pages/insurance/buy";


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
  useParams
} from "react-router-dom";

function App() {
  

  
  return (
    <Routes>
      {/*
      <Route path="/" element={<Login />}> </Route> */}
      
      <Route path="/" element={<Layout />}>

        <Route path="/insurance/premium" element={<InsurancePremiumFilter />} />
        <Route path="/insurance/premium/list" element={<InsuranceList />} />
        <Route path="/insurance/premium/compare" element={<CompareDetail />} />
        <Route path="/insurance/premium/customer" element={<CustomerSelect />} />
        <Route path="/insurance/premium/quotation" element={<Quotation />} />
        <Route path="/insurance/premium/buy" element={<Buy />} />

      </Route>
    </Routes>
  );
  
}




function Home() {
  return (
    <div></div>
  );
}
function Layoutadmin() {
  return (
    <div className="page">
      <Layout />
      <div className="main-content app-content">

      </div>
    </div>

  );
}
export default App;
