import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import logger from "redux-logger";
import rootReducer from "../slices";

const store = configureStore({
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      serializableCheck: false,
    });

    if ('development'== 'development') {
     
      middleware.push(logger);
    }

    return middleware;
  },
  reducer: rootReducer,
});

const useAppDispatch = () => useDispatch();
const useAppSelector = (selector, equalityFn) => useSelector(selector, equalityFn);

export { store, useAppDispatch, useAppSelector };
