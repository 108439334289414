import Select from 'react-select';


export function  SelectS1({name,onChange,className,options,placeholder,isInvalid,value,getOptionLabel,getOptionValue}) {


    return (
        <Select
            as="select"
            name={name}
            onChange={onChange}
            className={className}
            options={options}
            placeholder={placeholder}
            isInvalid={isInvalid}
            value={value}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            styles={{
                control: (baseStyles, state) => ({ 
                    
                    ...baseStyles,
                    height:'43.5px',
                    borderRadius:'8px',
                    border: isInvalid ?   '1px solid #dc3545' : '1px solid rgba(76, 78, 100, 0.22)',
                    paddingLeft:'10px'
                }),
                placeholder: (baseStyles) => {
                    return {
                        ...baseStyles,
                        color: '#999999c9',
                        fontWeight:'300'
                    }
                }
            }}
        />
    )
}