
import React, { useState,useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import {useNavigate,useLocation } from "react-router-dom";
import Text from 'fragments/Text';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
export const InsuranceCompare = ({compare,setCompare,formdata}) => {

    const navigate = useNavigate();

    return (<>
        <div className='position-fixed bottom-0 start-0 bg-white w-100' style={{height:'93px',zIndex:9999}}>
            <Row className='align-items-center h-100'>
                {
                    compare && compare.map((item,index) => 
                        
                        <Col style={{borderRight: '1px solid rgba(166, 167, 168, 0.2)'}}>
                            <InsuranceCompareItem data={item}  compareObj={{compare,setCompare}} />
                        </Col>
                    
                    )
                }

                <Col className='text-center'>
                    <button className='btn btn-st-1'
                        disabled={!compare}
                        onClick={() => {
                            navigate('/insurance/premium/compare',{state:{compare:compare,formdata:formdata}});
                        }}
                    >
                        ทำรายการต่อ
                    </button>
                </Col>
            </Row>
        </div>
    </>)
}

const InsuranceCompareItem = ({data,compareObj}) => {


    return (
        <>
            <div className='d-flex align-items-center mx-ato justify-content-center' style={{height:'93px'}}>
                <Image src={data?.insurance_image} style={{width:'50px',height:'50',objectFit:'cover'}} />
                <div className='ms-2'>
                    <div className='mb-1'> <Text c="#000000" s="14px" w={700} >{data?.insurance_type} / {data?.repair_type} </Text> </div>
                    <div className=''> 
                        <Text c="#000000" s="18px"  w={700} lh="1" > 
                            <CurrencyFormat 
                                value={data?.price}
                                displayType={'text'} 
                                thousandSeparator={true} 
                                prefix={''} 
                                decimalScale={2} 
                                fixedDecimalScale={true}
                            /> 
                        </Text>  
                    </div>
                </div>
                <div className='ms-4'>
                    <button className='btn btn-close-st-1'
                    onClick={() => {
                        compareObj.setCompare(prev => prev.filter(item => item.id !== data?.id))
                    }}
                    ></button>
                </div>
            </div>
        </>
    )
}

