
import Form from 'react-bootstrap/Form';
import React, { useState,useEffect,useRef } from 'react';
import {useNavigate,useLocation } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import CurrencyFormat from 'react-currency-format';
import Image from 'react-bootstrap/Image';
import {BrandCar} from 'layouts/BrandSelect';
import Text from 'fragments/Text';
import { Formik } from 'formik';
import * as yup from 'yup';
import { CustomerListSearch } from 'layouts/CustomerListSearch';
import { ModalAllder } from 'layouts/ModalAllder';
import { SelectS1 } from 'fragments/Select';

import {CurrencyFormatText} from 'fragments/Currency';
import { ProvinceList } from 'provinces/province';

export function  CustomerSelect() {

    const navigate = useNavigate();
    const location = useLocation();

    const [dataselected, setDataselected] = useState({
        "car_brand":null,
        "car_year":null,
        "car_model":null,
        "car_sub_model":null,
        "insurance_type":null,
        "original_insurance_fund":null,
        "insurance_fund":null,
        "car_province":null,
        "car_code":null,
    });


    let defaultForm = {
        "name":'',
        "tel":'',
        "car_license_plate":''
    }

    const [formsearchcustomer, setFormsearchcustomer] = useState(defaultForm)

    
    const formRef = useRef();
    const [modalCustomer, setModalCustomer] = useState({
        open:false,
        x:0,
        y:0,
        width:900,
        height:560,
        pin:false,
        mode:'normal',
        modalname:"customer"
    });

    const handleSearch = () => {
        setModalCustomer( (prev) => ({...prev,open:true}) )
    }

    const handleSelectCustomer = (customer) => {
        setFormcustomer(customer)
    }

    const [formcustomer, setFormcustomer] = useState({person_type:1})


    const handleSubmitForm = () => {

        if (formRef.current) {
          formRef.current.submitForm();
        }
      }


    const schema = yup.object().shape({
        person_type: yup.number().required(),
        car_license_plate_type: yup.number().required(),
        car_license_plate: yup.string().required(),
        province: yup.string().required(),
    });

    const prefixType = [
        { value: 'นาย', label: 'นาย' },
        { value: 'นาง', label: 'นาง' },
        { value: 'นางสาว', label: 'นางสาว' },
        { value: 'ไม่ระบุ', label: 'ไม่ระบุ' },
    ]


    const businessType = [
        { value: 1, label: 'บริษัทจำกัด' },
        { value: 2, label: 'บริษัทมหาชนจำกัด' },
        { value: 3, label: 'ห้างหุ้นส่วนสามัญ' },
        { value: 4, label: 'ห้างหุ้นส่วนจำกัด' },
        { value: 5, label: 'ร้านค้า' },
        { value: 6, label: 'สถาบันการศึกษา' },
        { value: 7, label: 'รัฐวิสาหกิจ' },
        { value: 8, label: 'ภาครัฐ' },
        { value: 9, label: 'คณะบุคคล' },
        { value: 10, label: 'สโมสร' },
        { value: 11, label: 'กองทุน' },
        { value: 12, label: 'มูลนิธิ' },
        { value: 13, label: 'วัด' },
        { value: 14, label: 'สำนักสงฆ์' },
        { value: 15, label: 'อื่นๆ' },
    ]

    const [compulsory, setCompulsory] = useState(false);

    const [compare, setCompare] = useState([]);

    const [quotation, setQuotation] = useState({});


    useEffect(() => {


        if(location.state){
            if(location.state){
                setQuotation((prev) => ({
                    ...prev,
                    compare:location.state.compare,
                    car:location.state.car,
                    compulsory:location.state.compulsory,
                }))
            }
            
        }
    }, [location]);



    
    return (
        <div >
            <div className="fs-20 tc-7 className='ps-5' mb-4 lh-1">ภาคสมัครใจ </div>

            <div className='row'>
                <div className='col-auto'>
                    <div className='card mb-4' style={{maxWidth:"800px",border:0,boxShadow: '0px 0px 20px 0px #4C4E6466'}}>
                        <div className='card-body'>

                            <div className="fs-20 tc-7 className='ps-5' mb-3 mt-4 lh-1">ข้อมูลลูกค้า </div>
                            <div className='py-3'>
                                <Row className='gy-3 align-items-end'>
                                    <Col lg="3">
                                        <Form.Group className="mb-3" controlId="">
                                            <Form.Label className='fs-14'>ชื่อลูกค้า</Form.Label>
                                            <Form.Control 
                                            
                                            className='fs-16' 
                                            type="text" 
                                            placeholder="ค้นหาจากชื่อ" 
                                            value={formsearchcustomer.name}
                                            onChange={(e) => {
                                                setFormsearchcustomer((prev) => ({ ...prev, name: e.target.value }) )
                                            }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3">
                                        <Form.Group className="mb-3" controlId="">
                                            <Form.Label className='fs-14'>เบอร์โทร</Form.Label>
                                            <Form.Control className='fs-16' type="text" maxLength={10} minLength={10} placeholder="ค้นหาจากเบอร์โทร" 
                                            value={formsearchcustomer.tel}
                                            onChange={(e) => {
                                                setFormsearchcustomer((prev) => ({ ...prev, tel: e.target.value }) )
                                            }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3">
                                        <Form.Group className="mb-3" controlId="">
                                            <Form.Label className='fs-14'>ทะเบียนรถ</Form.Label>
                                            <Form.Control className='fs-16' type="text" placeholder="ค้นหาจากทะเบียนรถ" 
                                            value={formsearchcustomer.car_license_plate}
                                            onChange={(e) => {
                                                setFormsearchcustomer((prev) => ({ ...prev, car_license_plate: e.target.value }) )
                                            }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="">
                                        <Button 
                                            onClick={() => handleSearch() }
                                            style={{
                                                background: '#FFA319',
                                                border:'1px solid #FFA319',
                                                color:"#FFF",
                                                fontSize:'16px'
                                            }}
                                        >  
                                            <Image src="/images/icon/search-normal.png" width={"16px"} style={{marginTop:'-2px',marginRight:'8px'}} />
                                            ค้นหา
                                        </Button>
                                        <Button 
                                            onClick={() => setFormsearchcustomer(defaultForm) }
                                            className='btn-link text-decoration-none' 
                                            style={{
                                                color:"#4C4E64",
                                                fontSize:'16px'
                                            }}
                                        >  
                                            
                                            ล้างค่า
                                        </Button>
                                        
                                        </Form.Group>
                                    </Col>

                                </Row>
                            </div>

                        </div>
                    </div>
                    <div className='card' style={{maxWidth:"800px",border:0,boxShadow: '0px 0px 20px 0px #4C4E6466'}}>
                        <div className='card-body p-4'>
                            
                            <div className='mb-4'><Text c='#000000' s='20px' w={600}>ข้อมูลลูกค้า</Text></div>
                            <Formik
                                innerRef={formRef}
                                initialValues={formcustomer}
                                validationSchema={schema}
                                enableReinitialize
                                onSubmit={async (values) => {

                                    navigate('/insurance/premium/quotation',{state:{quotation:quotation,customer:values}});
                                    
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                    submitForm
                                }) => (
                                    <form onSubmit={handleSubmit}>

                                    <Row className=''>
                                        <Col lg="6">
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>ประเภทผู้ถือประกัน *</Form.Label>
                                                <div className='inlineForm p-2'
                                                    style={{
                                                        border:!!errors.person_type ? '1px solid #dc3545' : 0,
                                                        borderRadius:'8px'
                                                    }}
                                                >
                                                    <Form.Check
                                                        inline
                                                        label="บุคคลทั่วไป"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-radio-1`}
                                                        checked={values.person_type == 1}
                                                        onChange={() => {
                                                            setFieldValue('person_type',1)
                                                        }}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="บุคคลทั่วไป"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-radio-2`}
                                                        checked={values.person_type == 2}
                                                        onChange={() => {
                                                            setFieldValue('person_type',2)
                                                        }}
                                                    />
                                                </div>
                                            </Form.Group>
                                            
                                        </Col>
                                        
                                        <Col lg="6">
                                            {
                                                values.person_type == 1 ? (
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>คำนำหน้า </Form.Label>
                                                        <SelectS1
                                                            as="select"
                                                            name="prefix"
                                                            onChange={(e) => {
                                                                setFieldValue('prefix', e.label)
                                                            }}
                                                            className={!!errors.prefix ? ('w-100 select-st-2 isInvalid ') : 'w-100 select-st-2'}
                                                            options={prefixType}
                                                            placeholder="เลือกคำนำหน้า"
                                                            isInvalid={!!errors.prefix}
                                                            value={
                                                                prefixType.filter(
                                                                    item => item.label === values.prefix,
                                                                )
                                                            }
                                                        />
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>ประเภทกิจการ </Form.Label>
                                                        <SelectS1
                                                            as="select"
                                                            name="business_type"
                                                            onChange={(e) => {
                                                                setFieldValue('business_type', e.label)
                                                            }}
                                                            className={!!errors.business_type ? ('w-100 select-st-2 isInvalid ') : 'w-100 select-st-2'}
                                                            options={businessType}
                                                            placeholder="เลือกประเภทกิจการ"
                                                            isInvalid={!!errors.business_type}
                                                            value={
                                                                businessType.filter(
                                                                    item => item.value === values.business_type,
                                                                )
                                                            }
                                                        />
                                                    </Form.Group>
                                                )
                                            }
                                            
                                        </Col>
                                        <Col lg="6">
                                            {
                                            values.person_type == 1 ? (
                                            <Form.Group className="mb-3" controlId="">
                                                <Form.Label>ชื่อ </Form.Label>
                                                <Form.Control 
                                                type="text" 
                                                placeholder="ชื่อ" 
                                                onChange={handleChange}
                                                value={values.name}
                                                className='form-st-1'
                                                />
                                            </Form.Group>
                                            ) : (
                                                <Form.Group className="mb-3" controlId="">
                                                    <Form.Label>ชื่อกิจการ </Form.Label>
                                                    <Form.Control 
                                                    type="text" 
                                                    placeholder="ชื่อ" 
                                                    onChange={handleChange}
                                                    value={values.business_name}
                                                    className='form-st-1'
                                                    />
                                                </Form.Group>
                                            )}
                                        </Col>
                                        {
                                        values.person_type == 1 ? (
                                            <Col lg="6">
                                                <Form.Group className="mb-3" controlId="">
                                                    <Form.Label>นามสกุล </Form.Label>
                                                    <Form.Control 
                                                    type="text" 
                                                    placeholder="นามสกุล" 
                                                    onChange={handleChange}
                                                    value={values.surname}
                                                    className='form-st-1'
                                                    />
                                                </Form.Group>
                                            </Col>
                                        ) : '' }
                                        <Col lg="6">
                                            <Form.Group className="mb-3" controlId="">
                                                <Form.Label>เบอร์โทร </Form.Label>
                                                <Form.Control 
                                                type="text" 
                                                name='tel'
                                                placeholder="เบอร์โทร" 
                                                onChange={handleChange}
                                                value={values.tel}
                                                className='form-st-1'
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg="6">
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>ประเภทจดทะเบียน *</Form.Label>
                                                <div className={`inlineForm p-2 `} 
                                                    style={{
                                                        border:!!errors.car_license_plate_type ? '1px solid #dc3545' : 0,
                                                        borderRadius:'8px'
                                                    }}
                                                >
                                                    <Form.Check
                                                        inline
                                                        label="รถจดทะเบียน"
                                                        name="group2"
                                                        type="radio"
                                                        id={`group2-radio-1`}
                                                        checked={values.car_license_plate_type == 1}
                                                        onChange={() => {
                                                            setFieldValue('car_license_plate_type',1)
                                                        }}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="ป้ายแดง"
                                                        name="group2"
                                                        type="radio"
                                                        id={`group2-radio-2`}
                                                        checked={values.car_license_plate_type == 2}
                                                        onChange={() => {
                                                            setFieldValue('car_license_plate_type',2)
                                                        }}
                                                    />
                                                </div>
                                            </Form.Group>
                                            
                                        </Col>
                                        <Col lg="6">
                                            <Form.Group className="mb-3" controlId="">
                                                <Form.Label>ทะเบียนรถยนต์* </Form.Label>
                                                <Form.Control 
                                                type="text" 
                                                name='car_license_plate'
                                                placeholder="รวย888" 
                                                onChange={handleChange}
                                                isInvalid={!!errors.car_license_plate}
                                                value={values.car_license_plate}
                                                className='form-st-1'
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg="6">
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>จังหวัด *</Form.Label>
                                                <SelectS1
                                                    as="select"
                                                    name="province"
                                                    onChange={(e) => {
                                                        setFieldValue('province', e.id)
                                                    }}
                                                    className={!!errors.province ? ('w-100 select-st-2 isInvalid ') : 'w-100 select-st-2'}
                                                    options={ProvinceList}
                                                    placeholder="เลือกจังหวัด"
                                                    isInvalid={!!errors.province}
                                                    value={
                                                        ProvinceList.filter(
                                                            item => item.id === values.province,
                                                        )
                                                    }
                                                    getOptionLabel={(option) => option.name_th}
                                                    getOptionValue={(option) => option.id}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    
                                    </form>
                                )}
                            </Formik>

                            
                        </div>
                    </div>
                    <div className='py-5'></div>
                </div> 
                <div className='col'>
                    <div style={{width:'430px'}}>
                        <div
                            style={{
                                border: '1px solid #8955C1',
                                padding:'6px 10px',
                                paddingBottom:'3px',
                                borderRadius: '8px',
                                background:'#FFF',
                                width:"160px",
                                marginLeft:'70px'
                            }}
                            className='mb-3 mx-auto'
                        >
                            <Form.Check
                                style={{color:'#8955C1',lineHeight:2}}
                                type="checkbox"
                                label='ซื้อพร้อม พรบ.'
                                id={`check-atc`}
                                checked={quotation.compulsory}
                                onChange={(e) => {
                                    if(e.target.checked){
                                        setQuotation((prev) => ({
                                            ...prev,
                                            compulsory:true
                                        }))
                                    }else{
                                        setQuotation((prev) => ({
                                            ...prev,
                                            compulsory:false
                                        }))
                                    }
                                }}
                                
                            />
                        </div>

                        
                        <div className='card card-st-2 border-0 overflow-hidden' >
                            <div className='card-body p-0'>
        
                                <table className='table table-st-2' >
                                    <thead>
                                        <tr className='row-head-2'>
                                            <td className='py-3 ps-4'>โลโก้</td>
                                            <td className='py-3'>บริษัท/ประเภท</td>
                                            <td className='py-3'>ราคาเบี้ย</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { quotation.compare &&  quotation.compare.map((item,index) =>  
                                            <tr key={index}>
                                                <td>
                                                    <Image className='ms-3' src={`/images/company/${item.logo}`} style={{width:'50px',height:'50',objectFit:'cover'}} />
                                                </td>
                                                <td>
                                                    <div><Text c="#1D1D1D" s='14px' w={600} > {item.name}</Text></div>
                                                    <div><Text c="#1D1D1D" s='12px' > ประกัน{item.insurance_type}</Text> / <Text c="##1D1D1D" s='12px' > {item.repair_center}</Text></div>
                                                </td>
                                                <td>
                                                    <Text c='#1D1D1D' s='14px' w={600}><CurrencyFormatText value={item.price} /></Text>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                                <div className='text-center mb-4 pt-2'>
                                    <Button 
                                    onClick={() => handleSubmitForm()}
                                    className='btn btn-st-1 fs-16'
                                    
                                    >ออกใบเสนอราคา</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <ModalAllder  
                modal={modalCustomer} 
                setModal={setModalCustomer}  
                htitle={"ข้อมูลลูกค้า"} 
                hbtn={true}
            >
                <CustomerListSearch 
                    formsearchcustomer={formsearchcustomer} 
                    setModal={setModalCustomer} 
                    onSelect={(customer) => handleSelectCustomer(customer) }
                />
                
            </ModalAllder>
            
        </div>
    )
}