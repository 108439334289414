import * as getAllCarModelAction from "../../slices/master/carModel.slice";
import { http } from "../../../utils/http";
import { apiUrl } from "../../../constants/server/api";

export const getAllCarModel = (year,car_brand_id) => {
  return async (dispatch) => {
    dispatch(getAllCarModelAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${apiUrl}/car-model/list`,
        params: {
          year,
          car_brand_id
        },
      };

      const result = await http(config);
      dispatch(getAllCarModelAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      dispatch(getAllCarModelAction.fetchDataFailure());
    }
  };
};
